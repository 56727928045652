@use './variables' as *;

@mixin flex($alignItem: center, $justifyContent: center) {
	display: flex;
	align-items: $alignItem;
	justify-content: $justifyContent;
}

@mixin mobile {
	@media only screen and (max-width: $mobile-width) {
		@content;
	}
}

@mixin tablet {
	@media only screen and (max-width: $tablet-width) {
		@content;
	}
}
@mixin fullscreen {
	@media only screen and (min-width: $full-width) {
		@content;
	}
}
