$bodyBg: #fff;
$mainBg: #e4dddd;
$mainColor: #05a6fd;
$txtColor: #4e4d4f;
$purple: #8624db;
$orange: #ff9066;
$white: #fff;
$black: #000;
$green: #4caf50;
$red: #db190c;

$fontFamily: 'Roboto', sans-serif;

$fontSizeNormal: 1.125rem;
$fontSizeMedium: 1.25rem;
$fontSizeLarge: 1.5625rem;

$sideBarWidth: 16rem;
$sideBarLogoHeight: 120px;
$spacing: 48px;
$spacing-tb: 24px;
$tableSpacing: 20px;
$mobileSpacing: 12px;
$borderRadius: 30px;

$mobile-width: 669px;
$tablet-width: 1024px;
$full-width: 1025px;
