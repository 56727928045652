@use '../../scss/abstracts/' as *;
@use 'sass:math';

.sidebar {
	@include flex(stretch, flex-start);
	flex-direction: column;
	color: $txtColor;
	width: $sideBarWidth;
	background-color: $mainBg;
	height: 100vh;
	position: fixed;
	top: 0;
	z-index: 100;

	@include tablet {
		width: 100vw;
		background-color: #d9d9d9;
		color: $white;
		z-index: 1;
	}

	&__logo {
		@include flex();
		height: $sideBarLogoHeight;
		width: 100%;
		padding: 2% 2% 0;
		// margin-bottom: 5%;

		@include tablet {
			justify-content: space-between;
			height: math.div($sideBarLogoHeight, 2.5);
			// padding: 0 $tableSpacing;
			// padding-top: 0.5rem;
			margin-bottom: $tableSpacing;
		}
		@include mobile {
			justify-content: space-between;
			height: math.div($sideBarLogoHeight, 2);
			padding: 2% 1% 1%;

			margin-bottom: $mobileSpacing;
		}

		img {
			width: 90%;
			display: block;

			@include tablet {
				width: 40%;
			}
			@include mobile {
				width: 50%;
			}
		}

		.sidebar-close {
			cursor: pointer;
			display: none;

			i {
				font-size: 2rem;
			}

			@include tablet {
				display: block;
			}
			@include mobile {
				margin-left: 10px;
			}
		}
	}

	&__menu {
		@include flex(stretch, flex-start);
		flex-direction: column;
		flex-grow: 1;
		@include mobile {
			flex-grow: 0;
		}
		&__item {
			@include flex(center, flex-start);
			position: relative;
			margin-bottom: 2rem;
			padding-left: 5%;
			transition: color 0.3s ease-in-out;

			@include tablet {
				padding-left: $mobileSpacing;
			}
			// &:last-child {
			// 	margin-bottom: 3rem;
			// }
			&:hover {
				color: $mainColor;
			}

			&.active {
				font-weight: 700;
				color: $mainColor;

				@include tablet {
					color: $mainColor;
				}

				&::before {
					content: '';
					position: absolute;
					height: 100%;
					width: 5px;
					left: 0;
					top: 0;
					background-color: $mainColor;
					border-top-right-radius: 20px;
					border-bottom-right-radius: 20px;

					@include tablet {
						background-color: $mainColor;
					}
					@include mobile {
						border-top-right-radius: 10px;
						border-bottom-right-radius: 10px;
					}
				}
			}

			&__icon {
				--size: 40px;
				width: var(--size);
				height: var(--size);
				margin-right: 1rem;
				@include flex(center, center);

				@include mobile {
					margin-right: 0.5rem;
					--size: 30px;
					width: var(--size);
					height: var(--size);
					i {
						font-size: 1rem;
					}
				}

				i {
					font-size: 1.9rem;
				}
			}

			&:last-child {
				margin-top: auto;
				cursor: pointer;
			}
		}
		.logout {
			color: red;
			font-weight: 500;
		}
	}
}
